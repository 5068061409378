<template>
  <div id="cms-category-show">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Category - Show</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Name (English):"
                    label-for="name"
            >
              <b-form-input id="name" v-model="category.name" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Name (Dutch):"
                    label-for="name_nl"
            >
              <b-form-input id="name_nl" v-model="category.name_nl" disabled></b-form-input>
            </b-form-group>
            <router-link class="link-color" :to="{ name: 'CmsCategories' }" @click.prevent>
              <b-button type="reset" variant="dark">Cancel</b-button>
            </router-link>
            <router-link class="link-color" :to="{ name: 'CmsCategoryUpdate' }" @click.prevent>
              <b-button type="reset" variant="warning">Edit</b-button>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'

  export default {
    data() {
      return {
        loading: true,
        category: [],
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get(`https://batavia-backend.herokuapp.com/api/category/${this.$route.params.id}`)
          .then(response => (
              this.category = response.data
          ))
          .finally(() => {
            this.loading = false
          })
    }
  }
</script>